import LazyBlockService from "../services/lazy-block-service";

function loadElement(element) {
  const { block: lazyBlock = null, useScriptTags = false, container = null } = element.dataset;
  if (lazyBlock !== null) {
    let containerEl = element;
    LazyBlockService.getBlockHtml(lazyBlock).then((html) => {
      if (container) {
        containerEl = document.querySelector(container);
      }

      containerEl.innerHTML = html;
      if (useScriptTags) extractScriptTags(containerEl);

      document.body.dispatchEvent(new CustomEvent('lazy-block-loaded', {
          detail: lazyBlock,
        }));
    });
    
    return;
  }
  
  applyResourceDatasetSource(element);
}

function extractScriptTags(element) {
  const scripts = element.getElementsByTagName("script");
  for (const script of scripts) {
    if(!script.src) continue;

    const scriptEl = document.createElement("script");
    scriptEl.src = script.src;
    scriptEl.type = script.type;
    document.body.appendChild(scriptEl);
    script.remove();
  }
}

function applyResourceDatasetSource(lazyElement) {
  if (lazyElement.dataset.src) {
    lazyElement.src = lazyElement.dataset.src;
    lazyElement.onload = function () {
      var loader = this.parentElement.querySelector(".stand-alone-loader");
      loader instanceof Element && loader.remove();
    };
  } else if (lazyElement.dataset.backgroundImage) {
    lazyElement.style.backgroundImage =
      "url(" + lazyElement.dataset.backgroundImage + ")";
  } else if (lazyElement.dataset.resource) {
    lazyElement.data = lazyElement.dataset.resource;
  }
}

export function lazyLoad() {
  var lazyElements = [].slice.call(document.querySelectorAll(".lazy"));

  if ("IntersectionObserver" in window) {
    var lazyElementObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          var lazyElement = entry.target;
          loadElement(lazyElement);

          lazyElementObserver.unobserve(lazyElement);
        }
      });
    });

    lazyElements.forEach(function (lazyElement) {
      lazyElementObserver.observe(lazyElement);
      lazyElement.classList.remove("lazy");
    });
  } else {
    // Fallback
    lazyElements.forEach(function (lazyElement) {
      lazyElement.classList.remove("lazy");
      loadElement(lazyElement);
    });
  }
}
document.addEventListener("DOMContentLoaded", lazyLoad);
document.addEventListener("page-content-changed", lazyLoad);
document.addEventListener("lazy-load", lazyLoad);

