const corporateAccordions = [];

window.addEventListener("scroll", handleNavFloat);
document.addEventListener('click', handleClickOutside);
document.addEventListener("DOMContentLoaded", function () {
  addClickHandler(".nav-burger-tabs__toggle", navBurgerToggle);
  addClickHandler(".nav-accordion__toggle", navAccordionToggle);

  [
    ...document.querySelectorAll(
      ".nav-member-mob__menu-section--collapsible .nav-member-mob__menu-section-title"
    ),
  ].forEach(function (el) {
    el.addEventListener("click", function () {
      el.classList.toggle("is-open");
    });
  });
});

function handleClickOutside(e) {
  if (!corporateAccordions.length) {
    return;
  }

  corporateAccordions.forEach(accordion => {
    if (!accordion.parentNode.contains(e.target)) {
      accordion.classList.remove('is-open');
    }
  });
}

function navBurgerToggle({ currentTarget }) {
  if (currentTarget.classList.contains("is-selected")) {
    return;
  }
  const tabList = document.querySelectorAll("[data-tab]");
  tabList.forEach((tab) => {
    tab.classList.remove("is-selected");
  });

  const activeTabElements = document.querySelectorAll(
    `[data-tab="${currentTarget.dataset.tab}"]`
  );
  activeTabElements.forEach((tabElement) => {
    tabElement.classList.add("is-selected");
  });
}

function checkCorporateAccordions(toggleElement) {
  return corporateAccordions.findIndex(accordion => accordion === toggleElement) !== -1;
}

function closeCorporateAccordionsExcept(toggleElement) {
  corporateAccordions.forEach(accordion => {
    if (accordion === toggleElement) {
      return;
    }

    accordion.classList.remove('is-open');
  });
}

function navAccordionToggle({ currentTarget }) {
  if (currentTarget.classList.contains('nav-accordion__toggle--corporate')) {
    closeCorporateAccordionsExcept(currentTarget);
    if (!checkCorporateAccordions(currentTarget)) {
      corporateAccordions.push(currentTarget);
    }
  }

  currentTarget.classList.toggle("is-open");
}

function handleNavFloat() {
  const navBar = document.querySelector('[ref="navRef"]');
  if (navBar === null) {
    return;
  }

  const position = navBar.getBoundingClientRect();
  const isFloating = position.y < 0;

  navBar.classList.toggle("is-floating", isFloating);
}

function addClickHandler(cssClassSelector, handlerMethod) {
  const elementList = document.querySelectorAll(cssClassSelector);
  
  elementList.forEach((element) => {
    element.addEventListener("click", handlerMethod);
  });
}
