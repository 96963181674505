import api from "../services/api";

window.dfpCallback = function (error, json) {
  const augur_json = error === null ? json : error;

  api.post("/site/register-augur/", {
    augur: JSON.stringify(augur_json),
  });
};

window.dfpTechnologyIndicator = function (dfptValue) {
  // do nothing
};
